.body {
	height: 90vh;
	margin: 0;
	
	background-color: #A0A3A8;

	//background-image: url("./imagenes/fondo.png");
	//background-repeat: no-repeat;
	//background-attachment: fixed;
	//background-size: cover;
	//background-position: center;
}

.ro { color: red; }
.ve { color: green; }
.am { color: yellow; }
.ne { color: black; }
.az { color: blue; }

.b { font-weight: bold; }

.main{	
	padding-top: 5px;
}

.maxh {
	height: 56vh;
}

.lista { overflow: scroll; scroll-padding-bottom:20px;
			bottom: 0px;}

table { border: none; }

tr:nth-child(even) {background: #CCC}
tr:nth-child(odd) {background: #FFF}

td.f {
	width:215px;
	height:200px;
	background: rgba(0,0,0,0.1);
	border-radius: 10px;
	border: 0px;
 }

td.es { width: 20px; background-color: #909297; }

td.datos {
	
	height: 200px;
	background-color: #909297;
	padding-left: 30px;
}


.cap { text-transform: capitalize; }
.cen { text-align: center; }

.loginForm {
	padding-top: 10px;
	background: rgba(0,0,0,0.1);
	color: white;
	font-family: 'LatoRegular';
	text-align: left;
	border-radius: 10px;
	border-style: solid;
	border-color: #131314;
	padding-bottom: 10px;
}

.cc { width: 30px; }
.cm { width: 40px; }
.cg { width: 110px; }
.cx { width:*; }

img {

	margin: 5px;
	max-width:180px;
	max-height:200px;
}

